import fIcon from "../components/services/serviceImages/1.png"
import cosmeticIcon from "../components/services/serviceImages/2.png"
import figureIcon from "../components/services/serviceImages/3.png"

const services = [
    {
        img: cosmeticIcon,
        heading: "Карбокситерапия",
        price: "600 грн",
        type: "cosmetic",
        desc: "Полноценная терапевтическая система для улучшения качества кожи, выраженного пролонгированного увлажнения и лифтинга. Система восстанавливает внутритканевое дыхание и функции клеток кожи, устраняет гиперкератоз, ряблость и атоничность, сокращает проявления морщин и линий."
    },
    {
        img: cosmeticIcon,
        heading: "Комбинированная чистка",
        price: "700 грн",
        type: "cosmetic",
        desc: "Косметологическая процедура очищения кожи, в процессе которой происходит отшелушивание отмерших клеток эпителия, устраняются поверхностные угри загрязнения, угри, удаляются воспалительные элементы, комедоны, милиумы."
    },
    {
        img: cosmeticIcon,
        heading: "Ультразвуковая чистка",
        price: "200 грн",
        type: "cosmetic",
        desc: "Аппаратный метод косметологии, под воздействия высокочастотных звуковых колебаний происходит процесс очищения кожи, слущивание ороговевших частичек, удаления избытка кожного жира, камедному и пигментных пятен."
    },
    {
        img: cosmeticIcon,
        heading: "Массаж классический",
        price: "300 грн",
        type: "cosmetic",
        desc: "Оказывает очень сильное воздействие на ткани, в результате чего улучшаются лимфо- и кровообращение, укрепляется мышечная ткань, повышается тургор кожи."
    },
    {
        img: cosmeticIcon,
        heading: "Пилинг",
        price: "600 грн",
        type: "cosmetic",
        desc: "Один из самых популярных методов эстетической коррекции кожи, в основе которых лежит контролируемые удаление определенного количества слоев кожи, инициирующее репаративные процессы для ремоделирования кожных структур."
    },
    {
        img:cosmeticIcon,
        heading: "Альгинатная маска для лица",
        price: "200 грн",
        type: "cosmetic",
        desc: "Средство для восстановления уставшей и тусклой кожи. При нанесении на кожу такая маска пластифицируется, оказывая дренажный и лифтинговый эффект."
    },
    {
        img: cosmeticIcon,
        heading: "Мезотерапия",
        price: "1400 грн",
        type: "cosmetic",
        desc: "Являет собой инъекционную технику введения препарата. Состав мезококтейля определяет косметолог в зависимости от состояние кожи, либо наличия дерматологических заболеваний."
    },
    {
        img: cosmeticIcon,
        heading: "Уход по типу кожи",
        price: "300 грн",
        type: "cosmetic",
        desc: "Состоит из определения Вашего типа кожи, подсказок насчёт использования домашней косметики и профессиональной процедуры."
    },
    {
        img: cosmeticIcon,
        heading: "Фотоомоложение",
        price: "500 грн",
        type: "cosmetic",
        desc: "Процедура, с помощью которой можно убрать устарелые участки кожи, а взамен создать новую, красивую и молодую кожу. Посредством процедуры мы можем достичь того, что кожа лица, шеи, декольте и рук будет выглядеть на несколько лет моложе. Рекомендовано количество 4-6 процедур."
    },
    {
        img: fIcon,
        heading: "Лазерная эпиляция лица",
        price: "от 100 до 400 грн",
        type: "laser",
        desc: "Как же быстро и без боли удалить нежелательные волосы? На помощь приходит диодный лазер. Во время процедуры с помощью короткого лазерного луча происходит сильное нагревание волоса, а затем и волосяного фолликула, что приводит к разрушению корней волос и прекращению их роста."
    },
    {
        img: fIcon,
        heading: "Лазерная эпиляция рук ",
        price: "от 100 до 500 грн",
        type: "laser",
        desc: "Руки-наиболее открытая часть нашего тела, поэтому важно придерживать их в ухоженном виде. На волосяные фолликулы оказывается лазерное воздействие инфракрасной вспышкой и достаточно сильным теплом, в результате которого они разрушаются, и рост волос прекращается."
    },
    {
        img: fIcon,
        heading: "Лазерная эпиляция ног",
        price: "от 100 до 500 грн",
        type: "laser",
        desc: "Эффективность метода лазерной эпиляции сложно преувеличить. Кроме  избавления  от растительности на бедрах и голенях, кожа становится приятнее и приобретает шелковистый оттенок. После проведённых процедур Вы навсегда сможете забыть, что такое ощущения дискомфорта после бритвы."
    },
    {
        img: fIcon,
        heading: "Лазерная эпиляция бикини",
        price: "500 грн",
        type: "laser",
        desc: "Эпиляция лазером – это наилучший способ уберечь себя от раздражения и покраснения от вросших волосков. Всегда предпочтительнее обращаться к более совершенным методам по уходу за телом, особенно если речь идет о такой деликатной области, как зона бикини."
    },
    {
        img: figureIcon,
        heading: "Коррекция фигуры по зонам",
        price: "420 грн за зону",
        type: "figure",
        desc: "VelaShape – это новейшая разработка, признанная революционной, которая объединила в себе биполярную радиочастотную энергию и энергию инфракрасного излучения, отрицательное пульсовое давление и механическое влияние на тканевые участки. "
    
    },
]  

export default services